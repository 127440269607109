<template>
  <div id="excess">
    <!-- 超标查询 -->
    <app-tree @select="onTreeSelect"></app-tree>
    <div class="content">
      <!-- 搜索区 -->

      <a-row>
        <a-col :span="8" class="">
          企业名称：<a-input
            v-model="QYMC"
            name="QYMC"
            :style="inputStyle"
            placeholder="请输入企业名称"
          />
        </a-col>
        <a-col :span="8">
          基站名称：<a-input
            v-model="JZMC"
            name="JZMC"
            :style="inputStyle"
            placeholder="请输入基站名称"
          />
        </a-col>
        <a-col :span="8">
          基站类型：
          <a-select
            :style="inputStyle"
            v-model="JZLX"
            name="JZLX"
            placeholder="请选择基站类型"
          >
            <a-select-option
              v-for="item in options"
              :key="item.FieldBH"
              :value="item.FieldBH"
            >
              {{ item.FieldMC }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row style="margin: 10px 0px">
        <a-col :span="8"
          >监测因子：<a-input
            v-model="JCYZMC"
            name="JCYZMC"
            :style="inputStyle"
            placeholder="请输入监测因子"
          />
        </a-col>
        <a-col :span="16">
          时间段：
          <a-range-picker
            @change="onChooseDate"
            :format="dateFormat"
            :placeholder="['请选择开始时间', '请选择结束时间']"
            :default-value="[
              moment($store.state.KSSJ1, dateFormat),
              moment($store.state.JSSJ, dateFormat),
            ]"
          >
          </a-range-picker>
          <a-button type="primary" @click="onSearch" style="margin: 0px 10px"
            >查询</a-button
          >
          <a-button type="primary" @click="onExport"> 导出 </a-button>
        </a-col>
      </a-row>
      <!-- table表格区域 -->
      <a-table
        class="table-wrapper"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        bordered
        size="small"
        :row-key="(record) => record.ida"
        :scroll="{ x: 500, y: 'calc(100vh - 180px)' }"
        :loading="loading"
      >
        <span slot="TS" slot-scope="text" style="text-align: center">{{
          text == 0 ? "正常" : "停运"
        }}</span>
      </a-table>
      <!-- 分页器区域 -->
      <a-pagination
        class="pagination-wrapper"
        :default-current="start"
        :total="alltotal"
        :defaultPageSize="limit"
        :show-total="
          (total, range) => `显示 ${range[0]}-${range[1]} 条，共 ${alltotal} 条`
        "
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import { http } from "@/utils/Http.js";
import dayjs from "dayjs";
import moment from "moment";

const columns = [
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    width: 200,
    align: "center",
  },
  {
    title: "基站名称",
    dataIndex: "station_name",
    width: 200,
    align: "center",
  },
  { title: "监测因子", dataIndex: "ItemName", width: 88, align: "center" },
  { title: "报警值", dataIndex: "BJZ", width: 80, align: "center" },
  { title: "超标时间", dataIndex: "BJKSSJ", width: 120, align: "center" },
  { title: "排放限值", dataIndex: "PFZ", width: 75, align: "center" },
  { title: "超标倍数", dataIndex: "CBBS", width: 75, align: "center" },
  {
    title: "状态",
    dataIndex: "TS",
    width: 60,
    align: "center",
    scopedSlots: { customRender: "TS" },
  },
];
export default {
  name: "home-excess-page",
  data() {
    return {
      title: "超标查询",
      layoutCol: "inline",
      labelCol: {},
      wrapperCol: {},
      inputStyle: { width: "150px" },

      options: [{ FieldBH: "0", FieldMC: "全部" }],

      // 筛选数据
      dateFormat: "YYYY-MM-DD",
      date1: dayjs().add(-1, "day").format("YYYY-MM-DD"),
      date2: dayjs().format("YYYY-MM-DD"),

      form: this.$form.createForm(this),

      QYMC: "",
      JZMC: "",
      // JZLX: ["0"],
      JZLX: "0",
      JCYZMC: "",

      // 列表数据
      data: [],
      columns,
      start: 1,
      limit: 20,
      loading: false,

      alltotal: 0,

      selectedData: {},
    };
  },
  components: {
    AppTree,
  },
  watch: {
    options: {
      handler(newName, oldName) {
        // // console.log(newName, oldName);
      },
      immediate: true,
    },
    limit(val) {
      // console.log('limit', val);
    },
    start(val) {
      // console.log('start', val);
    },
  },
  methods: {
    moment,
    //选择时效性
    onTreeSelect(item) {
      console.log("树的选择信息：", item);
      if (!item.leaf) {
        return;
      }
      let me = this;

      // var now = dayjs().format("YYYY-MM-DD");
      // var day = dayjs().add(-1, "day").format("YYYY-MM-DD");
      // console.log(now, day);
      // me.date1 = day;
      // me.date2 = now;

      me.selectedData = me.$store.state.selectedData;

      me.getSelected();

      me.onSearchBtn();
    },
    /**
     * 分页切换点击事件
     */
    onPageChange(page, pageSize) {
      let me = this;
      me.start = page || 1;
      // me.limit = pageSize;
      console.log("me.start, me.limit::", me.start, me.limit);
      me.onSearch();
    },

    /**
     * 查询按钮的点击事件
     */
    onSearchBtn() {
      let me = this;
      me.start = 1;
      me.onSearch();
    },
    onSearch() {
      let me = this;
      var EnterpriseBH = "";
      var SFBH = "";
      var SBH = "";
      var XBH = "";

      var Lx = me.selectedData.Lx;
      var BH = me.selectedData.id;
      switch (Lx) {
        case "2":
          SFBH = BH;
          break;
        case "3":
          SBH = BH;
          break;
        case "4":
          XBH = BH;
          break;
        case "5":
          EnterpriseBH = BH;
          break;
      }

      let obj = {
        CKM: me.$store.state.loggedIn.CKM,
        KSSJ: me.date1,
        JSSJ: me.date2,
        QYMC: me.QYMC || "",
        JZMC: me.JZMC || "",
        JZLX: me.JZLX[0] || "",
        JCYZMC: me.JCYZMC || "",
        EnterpriseBH,
        SFBH,
        SBH,
        XBH,
        start: (me.start - 1) * me.limit,
        limit: me.limit,
      };
      console.log(obj);
      me.getAjax(obj);
    },

    // 日期相关

    /**
     * 导出的点击事件
     */
    onExport(event) {
      let me = this;
      var EnterpriseBH = "";
      var SFBH = "";
      var SBH = "";
      var XBH = "";

      var Lx = me.selectedData.Lx;
      var BH = me.selectedData.id;
      switch (Lx) {
        case "2":
          SFBH = BH;
          break;
        case "3":
          SBH = BH;
          break;
        case "4":
          XBH = BH;
          break;
        case "5":
          EnterpriseBH = BH;
          break;
      }

      let ID = `${me.$store.state.loggedIn.CKM},${this.QYMC},${this.JZMC},${this.JZLX},${this.date1},${this.date2},${SFBH},${SBH},${XBH},${EnterpriseBH},${this.JCYZMC}`;
      console.log(ID);
      // console.log(this.QYMC);
      // console.log(this.JZMC);
      // console.log(this.JZLX);
      // console.log(this.JCYZMC);
      // console.log(this.date1);
      // console.log(this.date2);

      let HTTPURL = this.$store.state.HTTPURL;
      //let HTTPURL = "http://www.warpsoft.cn:7186";
      console.log(HTTPURL);

      window.open(
        "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A2764DHJJCCBCX&ID=" +
          ID +
          ""
      );
    },

    /**
     * 获取下拉选项
     */
    getSelected() {
      let me = this;
      http({
        url: "/data/q30/AutoTable_Select.aspx?TableName=SYS_DB_Dictionary",
        data: {
          Module: "BD",
          DataWhere: "LX=8",
        },
        success(obj) {
          let options = obj.Data;
          options.unshift({ FieldBH: "0", FieldMC: "全部" });
          me.options = options;
        },
        failure(obj) {},
      });
    },

    /**
     * 日期选择框的change事件
     */
    onChooseDate(dates, dates2) {
      let me = this;
      me.date1 = dates2[0];
      me.date2 = dates2[1];
      console.log(me.date1, me.date2);
    },

    getAjax(params) {
      let me = this;
      console.log(params);

      me.loading = true;
      http({
        // url: "/data/q30/HJJC_STATIONLIST_Exceed_Ext.aspx",
        // url: "/data/q30/HJJC_STATIONLIST_Exceed_Ext_V2.aspx",
        url: "/data/q30/HJJC_STATIONLIST_Exceed_Ext_PC.aspx",
        data: params,
        success(obj) {
          me.loading = false;
          console.log("obj::", obj);
          me.alltotal = obj.Data1[0].ROWSCOUNT;

          if (obj.Data && obj.Data.length > 0) {
            for (var i = 0; i < obj.Data.length; i++) {
              obj.Data[i].ida = "a" + i;
            }
          }
          me.data = obj.Data;
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    onShowSizeChange(current, pageSize) {
      console.log("current, pageSize::", current, pageSize);
      let me = this;
      me.start = current || 1;
      // me.limit = pageSize;
      me.getAjax();
    },
  },

  destoryed() {
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
#excess {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  height: 100%;

  .tree {
    width: 248px;
    height: 100%;
    padding: 20px 10px 0 0;
    margin: 0 10px 0 0;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;

    #tree {
      overflow: hidden;
    }
  }

  .tree::-webkit-scrollbar {
    width: 5px;
    background-color: #d9d9d9;
  }

  .tree::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #52c41a;
  }

  .content {
    background-color: #ffffff;
    padding: 20px 20px 0px 20px;
    flex: 1;
    .table-wrapper {
      // height: calc(100vh - 380px);
      min-height: 792px;
    }
    .pagination-wrapper {
      display: flex;
      padding: 20px;
      justify-content: flex-end;
    }
  }
}
</style>

